const Followers = () => {
  const req = [
    require("../images/img1.png"),
    require("../images/img2.png"),
    require("../images/img3.png"),
    require("../images/img4.png"),
    require("../images/img5.png"),
    require("../images/img6.png"),
    require("../images/img7.png"),
    require("../images/img8.png"),
    require("../images/img9.png"),
    require("../images/img10.png"),
  ];

  return (
    <div className="followersLayout">
      <p className="followText">
        You deserve <br /> To be happy.
        <br />
        You deserve to live a life you're excited about. Don't let others make
        you forget that
      </p>
    </div>
  );
};

export default Followers;
